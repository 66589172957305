import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: "AIzaSyCClhL9oHlL6iuu7JEGxWge5eEKK0DLvYo",
	authDomain: "saas-landing.firebaseapp.com",
	projectId: "saas-landing",
	storageBucket: "saas-landing.appspot.com",
	messagingSenderId: "4280297456",
	appId: "1:4280297456:web:408841629e76e10e551f35",
	measurementId: "G-NCW5LH0G1X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const firestore = getFirestore(app);

export { analytics, firestore };
