import React, {Fragment} from 'react';
import {
    Row,
    Col
} from 'react-bootstrap'

import styles from './landing.module.css';

const Landing = () => {
    return (
        <Fragment>
            <Row>
                <Col lg={12} className={styles.bglanding} id="home">
                    {/* <Image className={styles.bgimage} src={plain_bg} alt="background"/> */}
                </Col>
            </Row>
        </Fragment>
    )
};

export default Landing; 