import React from 'react'
import {
	Row,
	Col,
	Card
} from 'react-bootstrap';
import {
	Element
} from 'react-scroll';

import style from './feature.module.css';
import cursorClick from '../../assests/svg/cursor-click.svg';
import clockFast from '../../assests/svg/clock-lines.svg';
import piggyBankOutline from '../../assests/svg/piggy-bank-outline.svg';
import puzzle from '../../assests/svg/puzzle.svg';

const Feature = () => {
	return (
		<Element name='feature' id='feature'>
			<div className={style.feature}>
				<Row className={style.superHeaderRow}>
					<Col className={style.contentCenter}>
						<h6 className={style.featureSuperHeader}>
							Build your <strong style={{color: '#4e084e'}}>Dream Application</strong> with these features
						</h6>
					</Col>
				</Row>
				<Row className={style.featureRow1}>
					<Col lg={7} className={style.card_col}>
						<Card className={[style.feature_card].join(" ")}>
							<Card.Body className={style.card_body}>
								<Row style={{ height: '100%' }}>
									<Col lg={2} xs={2} className={style.cursorClickSVG}>
										<img src={cursorClick} alt='Cursor Click' />
									</Col>
									<Col>
										<Row className={style.easyClickColumn}>
											<h2>Easy to Integrate & Use</h2>
										</Row>
										<Row className={style.easyClickColumnContent}>
											<p>
												Effortlessly integrate powerful backend features and
												build complex API's to perform Data & Media operations
												for your applications with our user-friendly interface and pre-built modules.
											</p>
										</Row>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={5} className={style.card_col}>
						<Card className={[style.feature_card, style.featureRow1_col2_card].join(" ")}>
							<Card.Body className={style.card_body}>
								<Row style={{ height: '100%' }}>
									<Col lg={2} xs={2} className={style.clockLineSVG}>
										<img src={clockFast} alt='Quick Deployment' />
									</Col>
									<Col>
										<Row className={style.clockLineColumn}>
											<h2>Save Time & Effort</h2>
										</Row>
										<Row className={style.clockLineColumnContent}>
											<p>
												Building backend infra from scratch is not necessary. Concentrate on your application logic and Let us take care of the deployment procedure and backend complexity.
											</p>
										</Row>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className={style.featureRow2}>
					<Col lg={5} className={style.card_col}>
						<Card className={[style.feature_card].join(" ")}>
							<Card.Body className={style.card_body}>
								<Row style={{ height: '100%' }}>
									<Col lg={2} xs={2} className={style.clockLineSVG}>
										<img src={piggyBankOutline} alt='Quick Deployment' />
									</Col>
									<Col>
										<Row className={style.clockLineColumn}>
											<h2>Cost-Effective Solutions</h2>
										</Row>
										<Row className={style.clockLineColumnContent}>
											<p>
												With CloudEase, you can reduce infrastructure costs by leveraging our scalable backend solutions. Pay only for what you use and avoid unnecessary expenses.
											</p>
										</Row>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={7} className={style.card_col}>
						<Card className={[style.feature_card, style.featureRow2_col2_card].join(" ")}>
							<Card.Body className={style.card_body}>
								<Row style={{ height: '100%' }}>
									<Col lg={2} xs={2} className={style.cursorClickSVG}>
										<img src={puzzle} alt='Cursor Click' />
									</Col>
									<Col>
										<Row className={style.easyClickColumn}>
											<h2>Database & Third-Party Integration</h2>
										</Row>
										<Row className={style.easyClickColumnContent}>
											<p>
											Instantly integrate databases and dynamically generate models or schema during runtime. Additionally, integrate 3rd party data providers or software programmes like data APIs, transaction mails, etc.
											</p>
										</Row>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</Element>
	)
}

export default Feature