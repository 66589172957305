import React, { Fragment } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container'
import { Link, animateScroll as scroll, scroller } from 'react-scroll';

import "./navbar.css";

const scrollHandlerProduct = () => {
    scroller.scrollTo('about', {
        duration: 750,
        delay: 0,
        smooth: true,
        offset: -100
    });
    return;
}

const scrollHandlerPricing = () => {
    scroller.scrollTo('pricing', {
        duration: 600,
        delay: 0,
        smooth: true,
        offset: -100
    });
    return;
}

const scrollHandlerContactUs = () => {
    scroller.scrollTo('contactus', {
        duration: 1000,
        delay: 0,
        smooth: true,
        offset: 0
    });
    return;
}

const NavbarC = () => {
    return (
        <Fragment>
            <Container>
                <Navbar collapseOnSelect fixed="top" expand="lg" variant="dark" className="animate-navbar nav-theme justify-content-between">
                    <Navbar.Brand className="name" onClick={() => scroll.scrollToTop()}>CloudEase</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Link className="nav-link" onClick={scrollHandlerProduct}>Product</Link>
                            <Link className="nav-link" onClick={scrollHandlerPricing}>Pricing</Link>
                            <Link className="nav-link" onClick={scrollHandlerContactUs}>Contact Us</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </Fragment>
    );
}

export default NavbarC;
