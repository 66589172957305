import React from 'react'
import {
	Row,
	Col
} from 'react-bootstrap';
import {
	Element
} from 'react-scroll';

import style from './about.module.css';

const About = () => {
	return (
		<Element name='about'>
			<div className={style.about}>
				<Row>
					<Col lg={12} className={style.contentCenter}>
						<h6 className={style.aboutSuperHeaderText}>All the Essentials</h6>
					</Col>
				</Row>	
				<Row>
					<Col lg={12} className={style.contentCenter}>
						<h3 className={style.aboutHeaderText}>
							Everything You Need for Your Backend
						</h3>
					</Col>
				</Row>
				<Row>
					<Col className={style.contentCenter} lg={{offset: 2, span: 8}}>
						<h5 className={style.aboutText}>
							CloudEase offers an extensive set of features and tools to simplify your Backend Development and Deployment process. From creating API's, Database model, Authentication to Third-party and cloud Integration, our platform has you covered Explore the possibilities and bring your ideas to life with ease. 
						</h5>
					</Col>
				</Row>		
			</div>	
		</Element>		
	)
}

export default About;