import React, { useState } from 'react'
import {
	Card,
	Row,
	Col,
	Form,
	Button
} from 'react-bootstrap';
import {
	Element
} from 'react-scroll';
import { collection, addDoc } from 'firebase/firestore';

import { firestore } from '../../config/firebase';
import styles from './contactus.module.css';

const ContactUs = () => {

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [query, setQuery] = useState("");
	const [errorDisplay, setErrorDisplay] = useState(false);
	const [successDisplay, setSuccessDisplay] = useState(false)
	const [message, setMessage] = useState("");

	const submitContactUs = async () => {
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		if (!emailRegex.test(email)) {
			setErrorDisplay(true);
			setMessage("Invalid Email address");
		} else if(name.length < 1 || query.length < 1) {
			setErrorDisplay(true);
			setMessage("Missing fields, kindly fill all the fields");
		} else {
			await addDoc(collection(firestore, 'contactus'), {
				email: email,
				name: name,
				query: query,
				createdDate: new Date().toISOString()
			});

			setEmail('');
			setName('');
			setQuery('');
			setSuccessDisplay(true);
			setMessage('Your Queries reached us successfully, we\'ll get back to you soon!!');
		}
		
		setTimeout(() => {
			setErrorDisplay(false);
			setSuccessDisplay(false);
		}, 5000);
	}

	return (
		<Element name='contactus' id='contactus'>
			<div className={styles.contactus}>
				<Row>
					<Col className={styles.contactusHeader}>
						<h2>Get In Touch</h2>
					</Col>
				</Row>
				<Row className={styles.contactUsCardRow}>
					<Col lg={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
						<Card className={styles.contactusFormCard}>
							<Row>
								<Col sm={12} className={styles.dropMeLine}>
									<h2>Drop Me a Line</h2>
								</Col>
								<Col sm={12} className={styles.contactusText} >
									<p style={errorDisplay || successDisplay ? { marginBottom: 0 } : {}}>Our team is here to answer any Questions or Queries you may have.</p>
								</Col>
							</Row>
							{errorDisplay || successDisplay ? (
								<Row>
									<Col sm={12} className={styles.contactusText}>
										<p style={{ marginBottom: '8px', color: errorDisplay ? 'red' : 'green' }}>
											<strong>{message}</strong>
										</p>
									</Col>
								</Row>
							) : null
							}
							<Row>
								<Col sm={6} className={styles.contactusNameInput}>
									<Form.Control size='lg' type='text' placeholder='Name' 
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</Col>
								<Col sm={6}>
									<Form.Control size='lg' type='email' placeholder='Email'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Col>
							</Row>
							<Row>
								<Col sm={12}>
									<Form.Control size='lg' as='textarea' placeholder='Queries' className={styles.queryArea} 
										value={query}
										onChange={(e) => setQuery(e.target.value)}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={{ offset: 9 }}>
									<Button className={styles.subscribeNotifyButton} onClick={submitContactUs}>
										Send
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</div>
		</Element>
	)
}

export default ContactUs;