import React from 'react'
import {
	Row,
	Col,
	Button
} from 'react-bootstrap';
import {
	scroller
} from 'react-scroll';

import style from './MidBanner.module.css'

const MidBanner = () => {

	const scrollHandlerGetStarted = () => {
		scroller.scrollTo('subscribe', {
            duration: 600,
            delay: 0,
            smooth: true,
            offset: -70
        });
        return;
	}

	return (
		<div id='midBanner'>
			<div className={style.midBanner}>
				<Row>
					<Col className={style.midBannerSuperText} xs={{offset: 1, span: 10}}>
						<h2>Supercharge your productivity</h2>
						<h2>Get started with Backendify today</h2>
					</Col>
				</Row>
				<Row>
					<Col className={style.midBannerText} xs={{offset: 1, span: 10}} lg={{ offset: 2, span: 8 }}>
						<h5>
							Maximize your efficiency and accelerate your development process with Backendify. Start using Backendify now to unlock a new level of productivity and unleash the full potential of your full stack applications.
						</h5>
					</Col>
				</Row>
				<Row>
					<Col lg={12} className={style.midBannerButtonComponent}>
						<Button onClick={() => scrollHandlerGetStarted()} className={style.getStartedButton}>Get Started</Button>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default MidBanner;