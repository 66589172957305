import React, { useState } from 'react';
import {
	Row,
	Col,
	Form,
	Button
} from 'react-bootstrap';
import {
	Element
} from 'react-scroll';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc } from 'firebase/firestore';

import { firestore } from '../../config/firebase';
import styles from './subscribe.module.css';

const Subscribe = () => {

	const [email, setEmail] = useState("");

	const subscribeMail = async () => {

		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		if(!emailRegex.test(email)){
			toastTrigger('error', "Invalid Email address");
			return;
		}

		try {
			await addDoc(collection(firestore, 'subscribe'), {
				email: email,
				createdDate: new Date().toISOString()
			});
			toastTrigger('success', "Subscribed Successfully!");
		} catch (err) {
			toastTrigger('error', "Error Occured!, Please try again later!!");
		}		
	}

	const toastTrigger = (type, message) => {
		let config = {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			theme: "colored",
		}

		if (type === 'success') {
			toast.success(message, config);
		} else if (type === 'error') {
			toast.error(message, config);
		}
		setEmail("");
	}

	return (
		<Element name='subscribe'>
			{true ?
				<ToastContainer
					position="bottom-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
				/>
				: null}
			<div className={styles.subscribe}>
				<Row>
					<Col className={styles.subscribeHeaderText}>
						<h2>
							Get notified when we’re launching.
						</h2>
					</Col>
				</Row>
				<Row>
					<Col className={styles.subscribeText} lg={{ offset: 2, span: 8 }} xs={{ offset: 1, span: 10 }}>
						<h5>
							Stay in the loop! Be the first to know when we launch our cutting-edge backend solution. Subscribe to our email updates for insider news, early access, and exclusive offers. Join now to revolutionize your backend development journey
						</h5>
					</Col>
				</Row>
				<Row className={styles.subscribeBtnRow}>
					<Col lg={{ offset: 3, span: 4 }} xs={10}>
						<Form.Control size='lg' type='email' placeholder='Enter your email' className={styles.subscribeInput}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Col>
					<Col className={styles.subscribeBtnRowCol1}>
						<Button className={styles.subscribeNotifyButton} onClick={subscribeMail}>
							Notify me
						</Button>
					</Col>
				</Row>
			</div>
		</Element>
	)
}

export default Subscribe;