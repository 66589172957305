import React from 'react';
import { Container } from 'react-bootstrap';

import NavbarC from './components/Navbar/NavbarC';
import TitleMessage from './components/TitleMessage/TitleMessage';
import Landing from './pages/landing/Landing';
import About from './pages/About/About';
import Feature from './pages/feature/Feature';
import MidBanner from './pages/MidBanner/MidBanner';
import Pricing from './pages/pricing/Pricing';
import Subscribe from './pages/Subscribe/Subscribe';
import ContactUs from './pages/contactUs/ContactUs';

import './App.css';

const App = () => {

	// analytics
	return (
		<div style={{ position: 'relative' }}>
			<NavbarC />
			<Landing />
			<TitleMessage />
			<Container fluid className='container-box rounded'>
				<About />
			</Container>
			<Container fluid className='container-box rounded'>
				<Feature />
			</Container>
			<Container fluid className='container-box rounded pricing'>
				<Pricing />
			</Container>
			<div className='container-box rounded midBannerContainer'>
				<MidBanner />
			</div>
			<div className='container-box rounded subscribeContainer'>
				<Subscribe />
			</div>
			<div className='container-box rounded contactusContainer' style={{ backgroundColor: 'red' }}>
				<ContactUs />
			</div>
		</div>
	);
}

export default App;
