import React from 'react';
import styled from 'styled-components'; 
import {
    Row,
    Col,
    Button
} from 'react-bootstrap';
import {
    scroller
} from 'react-scroll';

import './title.css';

const MyTitleMessage = styled.h1`
  position: absolute;
  width: 100%;
  top: 33vh;
  z-index: 1;
  margin-top: auto;
  text-align: center;
  user-select: none !important;
  strong {
    font-size: 1.1em;
  }
  div {
    color: ${props => props.theme.textColor};    
    .main {
      font-size: 35px;
      margin-bottom: 0px !important;
    }
    
  }
`;

const Title = () => {

    const scrollHandlerLearnMore = () => {
		scroller.scrollTo('about', {
            duration: 700,
            delay: 0,
            smooth: true,
            offset: -100
        });
        return;
	}

    const scrollHandlerGetStarted = () => {
		scroller.scrollTo('subscribe', {
            duration: 1000,
            delay: 0,
            smooth: true,
            offset: -70
        });
        return;
	}

    return (
        <MyTitleMessage>
            <div className="titleMessage">
                <Row className="heading">
                <Col lg={12} className="name-font">
                        <p>A No-Code, Low-Code Platform</p>
                    </Col>
                    <Col lg={12} className="main-text text-center mb-3">
                          <h1>Welcome to CloudEase</h1>
                    </Col>                    
                    <Col lg={12} className="sub">
                        Cloud Ease provides Backend-as-a-serivce,
                        <br />
                        where you can create you backend application swiftly.
                    </Col>
                    <Col lg={12}>
                        <Button className='getStartedButton' onClick={() => scrollHandlerGetStarted()}>Get Started</Button>
                        <Button className='learnMoreButton' onClick={() => scrollHandlerLearnMore()}>Learn More</Button>
                    </Col>
                </Row>
            </div>
        </MyTitleMessage>
    )
}

Title.propTypes = {

}

export default Title
