import React from 'react'
import {
	Row,
	Col,
	Card,
	Table
} from 'react-bootstrap';
import {
	Element
} from 'react-scroll';

import styles from './pricing.module.css';

const Pricing = () => {

	const tableHeadText = [
		{ index: 0, text: 'Tier', class: [styles.tierTableHeader] },
		{ index: 1, text: 'vCPU', class: [] },
		{ index: 2, text: 'Memory', class: [] },
		{ index: 3, text: '# of API templates', class: [] },
		{ index: 4, text: '# of API calls (per month)', class: [] },
		{ index: 5, text: 'Media Handling', class: [] },
		{ index: 6, text: 'Pricing (per month)', class: [] },
	]

	const tableDataText = [
		{ index: 0, tierName: 'Free', cpu: '-', ram: '-', apiT: '1', apiC: '100', media: 'No', pricing: 'Free' },
		{ index: 1, tierName: 'Mini-0', cpu: '0.5', ram: '0.5 GiB', apiT: '10', apiC: '5K', media: 'No', pricing: '4$' },
		{ index: 2, tierName: 'Mini-1', cpu: '0.5', ram: '1 GiB', apiT: '25', apiC: '20K', media: 'No', pricing: '10$' },
		{ index: 3, tierName: 'Micro-0', cpu: '1', ram: '1 GiB', apiT: '50', apiC: '100K', media: 'Yes', pricing: '20$' },
		{ index: 4, tierName: 'Micro-1', cpu: '1', ram: '2 GiB', apiT: '50', apiC: '150K', media: 'Yes', pricing: '30$' },
		{ index: 5, tierName: 'Small', cpu: '2', ram: '4 GiB', apiT: '100', apiC: '500K', media: 'Yes', pricing: '50$' },
		{ index: 6, tierName: 'Custom', cpu: 'Custom', ram: 'Custom', apiT: 'Custom', apiC: 'Custom', media: 'Yes', pricing: 'Custom$' },
	]

	return (
		<Element name='pricing' id='pricing'>
			<Row>
				<Col className={[styles.pricingSuperHeader, styles.contentCenter].join(' ')}>
					<h2>Pricing plans</h2>
				</Col>
			</Row>
			<Row>
				<Col className={[styles.pricingHeader, styles.contentCenter].join(' ')}>
					<h2>Plan your tier according to your scale</h2>
				</Col>
			</Row>
			<Row>
				<Col lg={{ offset: 3, span: 6 }} className={[styles.pricingTextMessage, styles.contentCenter].join(' ')}>
					<h5>
						Flexible pricing options, from personal projects to enterprise applications, and everything in between. Contact Us for Custom plans and dedicated instance and Enjoy the benefits of CloudEase's powerful features and support.
					</h5>
				</Col>
			</Row>
			<Row className={styles.pricingRow}>
				<Col lg={{ span: 10 }}>
					<div className={styles.pricing}>
						<Card className={styles.pricingTableCard}>
							<Table striped responsive className={styles.pricingTable}>
								<thead>
									<tr>
										{
											tableHeadText.map(obj => {
												return (
													<th
														key={obj.index}
														className={
															[styles.tableDataHeaderCell, ...obj.class].join(' ')
														}
													>
														{obj.text}
													</th>
												)
											})
										}
									</tr>
								</thead>
								<tbody>
									{
										tableDataText.map(obj => {
											return (
												<tr key={obj.index}>
													<td>{obj.tierName}</td>
													<td>{obj.cpu}</td>
													<td>{obj.ram}</td>
													<td>{obj.apiT}</td>
													<td>{obj.apiC}</td>
													<td>{obj.media}</td>
													<td>{obj.pricing}</td>
												</tr>
											)
										})
									}
								</tbody>
							</Table>
						</Card>
					</div>
				</Col>
			</Row>
			<Row>
				<Col className={[styles.contentCenter, styles.pricingFooterText].join(' ')}>
					<h5>
						Additional cost of 0.05$ will be charged for every 1k (1000) Request Per Month (RPM) after quota.
					</h5>
				</Col>
			</Row>
		</Element>
	)
}

export default Pricing;